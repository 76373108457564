import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { MessageContext } from "../../Providers/MessageProvider";
import { goTo } from '../../Helpers/goTo';
import Logo from '../Logo';

const pages = ["about", 'config']

const MainMenu = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);

  const { getMessage } = useContext(MessageContext)

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (page) => {
    setAnchorElNav(null);
    if (!!page) goTo(page)
  };

  return (
    <>
      <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
        <IconButton
          size='large'
          aria-label='account of current user'
          aria-controls='menu-appbar'
          aria-haspopup='true'
          onClick={handleOpenNavMenu}
          color='inherit'
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id='menu-appbar'
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={Boolean(anchorElNav)}
          onClose={() => handleCloseNavMenu(null)}
          sx={{
            display: { xs: "block", md: "none" },
          }}
        >
          {pages.map((page) => (
            <MenuItem key={page} onClick={() => handleCloseNavMenu(page)}>
              <Typography textAlign='center'>{getMessage(`pages.${page}`)}</Typography>
            </MenuItem>
          ))}
        </Menu>
        <Logo />
      </Box>
      <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
        <Logo />
        {pages.map((page) => (
          <Button
            key={page}
            onClick={() => handleCloseNavMenu(page)}
            sx={{ my: 2, color: "white", display: "block" }}
          >
            {getMessage(`pages.${page}`)}
          </Button>
        ))}
      </Box>
    </>
  );
}

export default MainMenu;

import React from 'react'
import { isChrome, isEdge, isOpera } from 'react-device-detect'
const BleDisabled = () => {

  return (
    <>
      {!isChrome && !isEdge && !isOpera && (
        <p>Ce navigateur n'est pas compatible avec l'utilisation du bluetooth, veuillez utiliser google Chrome, microsoft Edge ou Opéra</p>
      )}
      {isChrome && (
        <>
          <p>Vous devez activer la fonction Bluetooth sur votre navigateur pour utiliser cette application.</p>
          <p>Copiez le lien ci dessous et collez le dans la barre d'adresse puis activez l'option "Experimental Web Platform features":</p>
          <a href="chrome://flags/#enable-experimental-web-platform-features">chrome://flags/#enable-experimental-web-platform-features</a>
        </>
      )}
      {isEdge && (
        <>
          <p>Vous devez activer la fonction Bluetooth sur votre navigateur pour utiliser cette application.</p>
          <p>Copiez le lien ci dessous et collez le dans la barre d'adresse puis activez l'option "Experimental Web Platform features":</p>
          <a href="edge://flags/#enable-experimental-web-platform-features">edge://flags/#enable-experimental-web-platform-features</a>
        </>
      )}
    </>
  )
}

export default BleDisabled

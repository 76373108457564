import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React, { useContext } from 'react'
import { DeviceContext } from '../../Providers/DeviceProvider'
import { MessageContext } from '../../Providers/MessageProvider'

const EnumInput = ({ name, options, value, onChange }) => {
  const { deviceName } = useContext(DeviceContext)
  const { getMessage } = useContext(MessageContext)

  const getDeviceMessage = (messageId) => getMessage(`${deviceName}.${messageId}`)

  const labelId = `${name}-label`
  const inputId = `${name}-input`
  const label = getDeviceMessage(name)

  return (
    <FormControl>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        labelId={labelId}
        id={inputId}
        value={value}
        onChange={(e, v) => onChange({ [name]: v.props.value })}
        label={label}
      >
        {options && options.map((opt, index) => (
          <MenuItem key={`opt_${index}`} value={opt.value}>
            {getDeviceMessage(opt.name)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default EnumInput
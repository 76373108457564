import { Button } from '@mui/material'
import { goHome } from '../Helpers/goHome'

const Logo = () => {
  return (
    <Button onClick={() => goHome()}>
      <img className='Logo' src="/logo192.png" alt="exxotest" />
    </Button>
  )
}

export default Logo
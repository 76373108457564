import { useContext } from 'react'
import { DeviceContext } from '../../Providers/DeviceProvider'
import About from '../Pages/About'
import BleDisabledPage from '../Pages/BleDisabled'
import Config from '../Pages/Config'
import ConnectionPage from '../Pages/Connection'
import DevicePage from '../Pages/Device'

const Navigation = () => {
  const { bleEnabled, bleConnected } = useContext(DeviceContext)

  const { URL } = document
  const route = String(URL).split('/').pop()

  return (
    <>
      {route === 'about'
        ? <About />
        : route === 'config'
          ? <Config />
          : !bleEnabled
            ? <BleDisabledPage />
            : !bleConnected
              ? <ConnectionPage />
              : <DevicePage />
      }
    </>
  )
}

export default Navigation
import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { load } from "protobufjs";
import { LogContext } from './LogProvider'
import { MessageContext } from './MessageProvider';
import { appendBuffer } from '../Helpers/appendBuffer';
import { stringToBytes } from '../Helpers/stringToBytes';
import { bytesToString } from '../Helpers/bytesToString';

const BLE_UART_SERVICE_ID = "6e400001-b5a3-f393-e0a9-e50e24dcca9e"
const BLE_TX_ID = "6e400002-b5a3-f393-e0a9-e50e24dcca9e"
const BLE_RX_ID = "6e400003-b5a3-f393-e0a9-e50e24dcca9e"
const EOM_STR = "____#____#"
const EOM_BUFFER = new Uint8Array(stringToBytes(EOM_STR))


export const DeviceContext = createContext()

const DeviceProvider = ({ children }) => {
  const [bleEnabled, setBleEnabled] = useState(false)
  const [bleConnected, setBleConnected] = useState(false)
  const [waitRequest, setWaitRequest] = useState(false)
  const [deviceName, setDeviceName] = useState(null)
  const [deviceSerial, setDeviceSerial] = useState(null)
  const [deviceConfig, setDeviceConfig] = useState(null)
  const [deviceState, setDeviceState] = useState({})
  const { log, clear } = useContext(LogContext)
  const { appendMessages } = useContext(MessageContext)

  const connectionStepRef = useRef() // Etape de connexion en cours
  const deviceRef = useRef() // Device bluetooth
  const iBapMessageRef = useRef() // Protobuf message handler
  const defaultDeviceStateRef = useRef({}) // Etat maquette par défaut
  const txCharacteristicRef = useRef() // Tx characteristic
  const rxCharacteristicRef = useRef() // Rx characteristic
  const rxBufferRef = useRef(new Uint8Array(0)) // Rx buffer

  useEffect(() => {
    setBleEnabled(!!navigator.bluetooth && !!navigator.bluetooth.getDevices);
  }, []);

  const handleDataReceived = useCallback((event) => {
    const { value } = event.target;
    rxBufferRef.current = appendBuffer(rxBufferRef.current, value.buffer);
    let eomIndex = rxBufferRef.current.indexOf(EOM_BUFFER[0], 0);
    let found = false;
    while (eomIndex !== -1 && !found) {
        let i = 0;
        while (i < EOM_BUFFER.length && rxBufferRef.current[eomIndex + i] === EOM_BUFFER[i]) {
            ++i;
        }
        if (i === EOM_BUFFER.length) {
            found = true;
        } else {
            eomIndex = rxBufferRef.current.indexOf(95, eomIndex + 1);
        }
    }
    if (eomIndex !== -1) {
      const msgBuffer = rxBufferRef.current.slice(0, eomIndex - 2) // -2, c'est pour le CRC
      const message = iBapMessageRef.current.decode(msgBuffer)
      log('device.message-received', { message })
      let newDeviceState = { ...defaultDeviceStateRef.current }
      if (!!(message?.ibapMessage?.stateMessage)) {
        Object.entries(message.ibapMessage.stateMessage)
          .forEach(([key, value]) => newDeviceState[key] = value)
      }
      setDeviceState(newDeviceState)
      log('device.new-state', newDeviceState)
      rxBufferRef.current = new Uint8Array(0)
      setWaitRequest(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDisconnection = useCallback((event) => {
    disconnect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const connect = useCallback(() => {
    if (bleEnabled) {
      try {
        clear()
        connectionStepRef.current = "getting-devices"
        navigator.bluetooth.getDevices()
          .then((devices) => {
            devices.forEach(device => {
              if (device.gatt.connected) device.gatt.disconnect()
              log('device.forgetting', { device })
              device.forget()
            })
            log("device.opening-selector")
            connectionStepRef.current = "opening-selector"
            return navigator.bluetooth.requestDevice({
              filters: [{ namePrefix: "MT-" }],
              optionalServices: [BLE_UART_SERVICE_ID]
            })
          })
          .then((device) => {
            deviceRef.current = device
            const [deviceName, deviceSn] = String(device.name).split('_')

            setDeviceName(deviceName)
            setDeviceSerial(`0000${deviceSn}`.slice(-4))
            const configFilename = `./devices/${deviceName}.json`
            connectionStepRef.current = "opening-selector"
            log("device.getting-device-config")
            return fetch(configFilename)
          })
          .then(response => response.json())
          .then(config => {
            const deviceName = String(deviceRef.current.name).split('_')[0]
            if (!!config?.lang) appendMessages(config.lang, deviceName)
            setDeviceConfig(config)
            if (config?.state) {
              defaultDeviceStateRef.current = Object.entries(config.state)
                .reduce((res, [key, { value }]) => {
                  return {
                    ...res,
                    [key]: value
                  }
                }, {})
              setDeviceState(defaultDeviceStateRef.current)
            }
            const protoFilename = `./devices/${deviceName}.proto`
            connectionStepRef.current = "getting-proto"
            log("device.getting-proto", { device: deviceRef.current })
            return load(protoFilename)
          })
          .then(root => {
            log("device.opening-proto", root)
            connectionStepRef.current = "opening-proto"
            return root.lookup("iBAP.GenericMessage")
          })
          .then(genericMessage => {
            iBapMessageRef.current = genericMessage
            log('device.connecting-device')
            connectionStepRef.current = "connecting-device"
            return deviceRef.current.gatt.connect()
          })
          .then((server) => {
            log('device.getting-service');
            connectionStepRef.current = "getting-service"
            return server.getPrimaryService(BLE_UART_SERVICE_ID);
          })
          .then((service) => {
            log('device.getting-characteristics', service)
            connectionStepRef.current = "getting-characteristics"
            return service.getCharacteristics()
          })
          .then((characteristics) => {
            rxCharacteristicRef.current = characteristics.find(({ uuid }) => uuid === BLE_RX_ID)
            txCharacteristicRef.current = characteristics.find(({ uuid }) => uuid === BLE_TX_ID)
            log('device.connected', characteristics)
            setBleConnected(true)
            connectionStepRef.current = "starting-notifications"
            return rxCharacteristicRef.current.startNotifications()
          })
          .then(() => {
            rxCharacteristicRef.current.addEventListener('characteristicvaluechanged', handleDataReceived)
            deviceRef.current.addEventListener('gattserverdisconnected', handleDisconnection)
            requestDeviceState()
          })
          .catch((error) => {
            log(`device.error.${connectionStepRef.current}`, error, "error");
          })
      }
      catch (error) {
        log(error.message, error, "error");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bleEnabled, clear, log])

  const disconnect = useCallback(() => {
    clear()
    log('device.disconnecting')
    rxCharacteristicRef.current.stopNotifications()
    rxCharacteristicRef.current.removeEventListener('characteristicvaluechanged', handleDataReceived)
    deviceRef.current.gatt.disconnect()
    setBleConnected(false)
    setTimeout(() => clear(), 3000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bleConnected])

  const sendDeviceMessage = useCallback((messageObject) => {
    const msg = iBapMessageRef.current?.create({ phoneMessage: messageObject })
    let txBuffer = iBapMessageRef.current?.encode(msg).finish()
    txBuffer = appendBuffer(txBuffer, EOM_BUFFER)

    txCharacteristicRef.current.writeValue(txBuffer)
      .then((resp) => {
        log(`Message ${Object.keys(messageObject).join(', ')} envoyé`, messageObject)
      })
      .catch((error) => {
        log(`Erreur d'envoi du message ${Object.keys(messageObject).join(', ')}`, error)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const requestDeviceState = useCallback(() => {
    sendDeviceMessage({ getState: true })
    setWaitRequest(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sendDeviceState = useCallback((newState) => {
    let pbState = {}
    setDeviceState(currentState => ({ ...currentState, ...newState }))
    Object.entries(newState).forEach(([key, value]) => {
      if (!!value) pbState[key] = value
    })
    log('Envoie nouvel etat', pbState)
    sendDeviceMessage({ setState: pbState })
    setWaitRequest(true)
    //setTimeout(() => requestDeviceState(), 4000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const context = {
    bleEnabled,
    bleConnected,
    waitRequest,
    deviceName,
    deviceSerial,
    deviceConfig,
    deviceState,
    connect,
    disconnect,
    requestDeviceState,
    sendDeviceState
  }
  return (
    <DeviceContext.Provider value={context}>
      {children}
    </DeviceContext.Provider>
  )
}

export default DeviceProvider

import { getDateCodeStr } from './getDateCodeStr';
import kebabCase from "lodash/kebabCase"

const getConfigFileName = (deviceName, description) => {
  return !!description?.length
    ? `${deviceName}_${kebabCase(description)}.ibap`
    : `${deviceName}_${getDateCodeStr()}.ibap`

}

export default getConfigFileName
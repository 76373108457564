import { Box, Button } from "@mui/material";
import BluetoothDisabledIcon from "@mui/icons-material/BluetoothDisabled";
import BluetoothIcon from "@mui/icons-material/Bluetooth";
import BluetoothConnectedIcon from "@mui/icons-material/BluetoothConnected";
import { useContext } from 'react';
import { DeviceContext } from '../../Providers/DeviceProvider';

const DeviceMenu = () => {
  const { bleEnabled, bleConnected, bleDevice, connect, disconnect } = useContext(DeviceContext);

  const bleStatusList = [
    {
      Icon: BluetoothDisabledIcon,
      message: "bluetooth-disabled",
      onClick: () => window.location.reload(true)
    },
    {
      Icon: BluetoothIcon,
      message: "bluetooth-enabled",
      onClick: connect
    },
    {
      Icon: BluetoothConnectedIcon,
      message: "bluetooth-connected",
      label: bleDevice?.name,
      onClick: disconnect
    },
  ];



  const bleStatusIndex =
    !bleEnabled
      ? 0
      : !bleConnected
        ? 1
        : 2

  const bleStatus = bleStatusList[bleStatusIndex];

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Button variant="contained" color='primary' title={bleStatus.message} onClick={bleStatus.onClick}>
        <bleStatus.Icon />
        {bleStatus.label || null}
      </Button>
    </Box>
  );
};

export default DeviceMenu;

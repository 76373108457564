import React, { createContext, useCallback, useEffect } from 'react'
import useLocalState from '../Hooks/useLocalState'
import get from 'lodash/get'
import set from 'lodash/set'

export const MessageContext = createContext({})

const getShortLangId = langIdStr => String(langIdStr).split('-')[0].toLowerCase()

const MessageProvider = ({ children }) => {
  const [langId, setLangId] = useLocalState("lang_id", getShortLangId(window.navigator.language))
  const [messages, setMessages] = useLocalState("messages", {})

  useEffect(() => {
    fetch(`/langs/${langId}.json`)
      .then(response => response.json())
      .then(json => {
        setMessages(messages => ({ ...messages, [langId]: json }))
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [langId])

  const getMessage = useCallback((messageKey) => {
    const message = get(messages?.[langId], messageKey) || messageKey
    return message
  }, [langId, messages])

  const formatMessage = useCallback((messageKey, data) => {
    let message = getMessage(messageKey)
    const matches = [...(new Set([...message.matchAll(/\{([\w.]+)\}/gim)].map(m => m[1])))]
    matches.forEach(propertyKey => message = message.replaceAll(`{${propertyKey}}`, get(data, propertyKey) || ""))
    return message
  }, [getMessage])

  const appendMessages = useCallback((newMessages, rootKey) => {
    if (newMessages?.[langId] && !!rootKey) {
      setMessages(messages => set(messages, `${langId}.${rootKey}`, newMessages[langId]))
    }
  }, [langId, setMessages])

  const context = {
    langId,
    setLangId,
    getMessage,
    formatMessage,
    appendMessages
  }
  return (
    <MessageContext.Provider value={context}>
      {children}
    </MessageContext.Provider>
  )
}

export default MessageProvider
import kebabCase from "lodash/kebabCase"
import getConfigFileName from './getConfigFileName'

export const saveDeviceConfig = (deviceName, description, state) => {
  const config = {
    name: deviceName + '_' + kebabCase(description),
    description,
    state
  }

  const file = new Blob([JSON.stringify(config, null, 2)], { type: 'text/plain;charset=utf-8' })

  const link = document.createElement("a")
  link.href = URL.createObjectURL(file)
  link.download = getConfigFileName(deviceName, description)

  document.body.appendChild(link)

  link.click()

  link.parentNode.removeChild(link)
}
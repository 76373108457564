import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import MainMenu from "../MainMenu/MainMenu";
import DeviceMenu from "../DeviceMenu/DeviceMenu";

function AppBar() {
  return (
    <MuiAppBar position='static' className="App-Header">
      <Container maxWidth='xl'>
        <Toolbar disableGutters>
          <MainMenu />
          <DeviceMenu />
        </Toolbar>
      </Container>
    </MuiAppBar>
  );
}
export default AppBar;

import { Button, Card, CardActions, CardContent, Typography } from '@mui/material'
import BluetoothIcon from "@mui/icons-material/Bluetooth";
import { useContext } from 'react'
import { LogContext } from '../../Providers/LogProvider'
import { MessageContext } from '../../Providers/MessageProvider'
import { DeviceContext } from '../../Providers/DeviceProvider';

const Connection = () => {
  const { LogView, hasLogs } = useContext(LogContext)
  const { getMessage } = useContext(MessageContext)
  const { connect } = useContext(DeviceContext)
  return (
    <>
      {!hasLogs && (
        <Card style={{ margin: 10 }}>
          <CardContent>
            <Typography variant="h5">
              {getMessage('connection.title')}
            </Typography>
            <Typography variant="body2">
              {getMessage('connection.message')}
            </Typography>
          </CardContent>
          <CardActions style={{ justifyContent: 'flex-end' }}>
            <Button
              startIcon={<BluetoothIcon />}
              onClick={() => connect()}
            >
              {getMessage('connection.connect')}
            </Button>
          </CardActions>
        </Card>
      )}
      <LogView />
    </>
  )
}

export default Connection
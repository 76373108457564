import "./App.css";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AppBar from "./Components/Layout/AppBar";
import Navigation from "./Components/Navigation/Navigation";
import DeviceProvider from "./Providers/DeviceProvider";
import LogProvider from "./Providers/LogProvider";
import MessageProvider from "./Providers/MessageProvider";


const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "#16274f",
    },
  },
});


function App() {
  return (
    <div className='App'>
      <ThemeProvider theme={theme}>
        <MessageProvider>
          <LogProvider>
            <DeviceProvider>
              <AppBar />
              <Navigation />
            </DeviceProvider>
          </LogProvider>
        </MessageProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;

import { Alert } from '@mui/material'
import React, { createContext, useCallback, useState, useMemo, useContext } from 'react'
import { MessageContext } from './MessageProvider'

export const LogContext = createContext([])

const LogProvider = ({ children }) => {
  const [logs, setLogs] = useState([])
  const { formatMessage } = useContext(MessageContext)

  const log = useCallback((messageId, data, type = "info") => {
    const message = formatMessage(messageId, data)
    type === "error"
      ? console.error(message, data || '')
      : console.log(message, data || '')
    setLogs(logs => ([...logs, { type, message }]))
  }, [formatMessage])

  const clear = useCallback(() => {
    console.clear()
    setLogs([])
  }, [])

  const LogView = useMemo(() => () => {
    return !logs.length
      ? null
      : logs.map(({ type, message }, index) => (
        <Alert severity={type} key={`log_${index}`}>
          {message}
        </Alert>
      ))
  }, [logs])

  return (
    <LogContext.Provider value={{ log, clear, LogView, hasLogs: !!logs.length }}>
      {children}
    </LogContext.Provider>
  )
}

export default LogProvider
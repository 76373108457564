import { Button, Card, CardActions, CardContent, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { LogContext } from '../../Providers/LogProvider'
import { MessageContext } from '../../Providers/MessageProvider'
import ExitIcon from '@mui/icons-material/ExitToApp';
import { goHome } from '../../Helpers/goHome'

const Config = () => {
  const [langs, setLangs] = useState([])
  const { log, clear } = useContext(LogContext)

  const { getMessage, langId, setLangId } = useContext(MessageContext)

  const getConfigMsg = useCallback((messageId) => {
    return getMessage(`config.${messageId}`)
  }, [getMessage])


  useEffect(() => {
    clear()
    fetch('/langs/langs.json')
      .then((resp) => resp.json())
      .then(newLangs => setLangs(newLangs))
      .catch(error => {
        log('config.error.lang', error)
      })
  }, [log, clear])

  const langLabel = getConfigMsg('lang')

  console.log({ langs, langId })
  return (
    <Card className='Config-Card'>
      <CardContent>
        <Typography variant="h5">
          {getConfigMsg('title')}
        </Typography>
        <FormControl>
          <InputLabel id={'langLabel'}>{langLabel}</InputLabel>
          <Select
            labelId={'langLabel'}
            id={'langInput'}
            value={langId}
            onChange={(e, v) => setLangId(v.props.value)}
            label={langLabel}
          >
            {langs && langs.map(({ name, description }, index) => {
              return (
                <MenuItem key={`opt_${index}`} value={name}>
                  {description}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </CardContent>
      <CardActions style={{ justifyContent: 'flex-end' }}>
        <Button
          startIcon={<ExitIcon />}
          onClick={() => goHome()}
        >
          {getConfigMsg('close')}
        </Button>
      </CardActions>
    </Card>
  )
}

export default Config
import { Button, Card, CardActions, CardContent, CardMedia, Link, Typography } from '@mui/material'
import React, { useCallback, useContext } from 'react'
import { MessageContext } from '../../Providers/MessageProvider'
import ExitIcon from '@mui/icons-material/ExitToApp';
import { goHome } from '../../Helpers/goHome';

const About = () => {
  const { getMessage } = useContext(MessageContext)

  const getAboutMsg = useCallback((messageId) => {
    return getMessage(`about.${messageId}`)
  }, [getMessage])

  return (
    <Card className='About-Card'>
      <CardMedia
        component="img"
        height="120"
        width={"40vw"}
        image={getAboutMsg('logo')}
        alt={getAboutMsg('title')}
      />
      <CardContent>
        <Typography variant="h5">
          {getAboutMsg('title')}
        </Typography>
        <Typography variant="h6">
          {getAboutMsg('subtitle')}
        </Typography>
        <Typography variant="body2">
          {getAboutMsg('description')}
        </Typography>
        <Typography variant="body1">
          {getAboutMsg('addr1')}
        </Typography>
        <Typography variant="body1">
          {getAboutMsg('tel')}
        </Typography>
        <Typography variant="body1">
          {getAboutMsg('fax')}
        </Typography>
        <Link href={getAboutMsg('url')} variant="body1">
          {getAboutMsg('sitename')}
        </Link>
      </CardContent>
      <CardActions style={{ justifyContent: 'flex-end' }}>
        <Button
          startIcon={<ExitIcon />}
          onClick={() => goHome()}
        >
          {getAboutMsg('close')}
        </Button>
      </CardActions>
    </Card>
  )
}

export default About
import { Button, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material'
import React, { useCallback, useContext, useEffect, useState, CSSProperties } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import { DeviceContext } from '../../Providers/DeviceProvider'
import { MessageContext } from '../../Providers/MessageProvider'
import ExitIcon from '@mui/icons-material/ExitToApp';
import SendIcon from '@mui/icons-material/Send';
import propertyInputs from '../DevicePropertyInputs';
import get from 'lodash/get'
import DeviceConfigManager from '../DeviceConfigManager/DeviceConfigManager';

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const Device = () => {
  const { waitRequest, deviceName, deviceSerial, deviceConfig, deviceState, requestDeviceState, sendDeviceState, disconnect } = useContext(DeviceContext)
  const { getMessage } = useContext(MessageContext)
  const { name, description, state: stateConfig } = deviceConfig || {}

  const [state, setState] = useState(deviceState || {})
  const [touched, setTouched] = useState(false)

  useEffect(() => {
    setState(deviceState || {})
    setTouched(false)
  }, [deviceState])

  const handleChange = useCallback(update => {
    setState(state => {
      let newState = { ...state }
      Object.entries(update).forEach(([key, value]) => {
        if (newState[key] !== undefined) {
          newState[key] = value
        }
      })
      return newState
    })
    setTouched(true)
  }, [])

  return (
    <React.Fragment>
      { waitRequest ?
        <Card className='Device-Card'>
          <DeviceConfigManager value={state} onChange={handleChange} />
          <CardMedia
            component="img"
            height="120"
            image={`/devices/${deviceName}.gif`}
            alt={deviceName}
            onClick={() => requestDeviceState()}
          />
          <CardContent>
            <Typography variant="h5">
              {name} <small>SN/{deviceSerial}</small>
            </Typography>
            <Typography variant="body2">
              {description}
            </Typography>
            <ClipLoader
              color={"#ffffff"}
              loading={waitRequest}
              cssOverride={override}
              size={150}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </CardContent>
        </Card>
      :
        <Card className='Device-Card'>
          <DeviceConfigManager value={state} onChange={handleChange} />
          <CardMedia
            component="img"
            height="120"
            image={`/devices/${deviceName}.gif`}
            alt={deviceName}
            onClick={() => requestDeviceState()}
          />
          <CardContent>
            <Typography variant="h5">
              {name} <small>SN/{deviceSerial}</small>
            </Typography>
            <Typography variant="body2">
              {description}
            </Typography>
            <div className='Device-Form'>
              {!!stateConfig && Object.entries(stateConfig).map(([key, { type, value, ...config }]) => {
                const PropertyInput = propertyInputs[type]
                return !!PropertyInput
                  ? <PropertyInput
                    name={key}
                    {...config}
                    value={get(state, key) ?? value}
                    onChange={handleChange}
                  />
                  : <p>{key} ?? {type}</p>
              })}
            </div>
          </CardContent>
          <CardActions style={{ justifyContent: 'flex-end' }}>
            <Button
              startIcon={<ExitIcon />}
              onClick={() => disconnect()}
            >
              {getMessage('device.disconnect')}
            </Button>
            <Button
              startIcon={<SendIcon />}
              onClick={() => sendDeviceState(state)}
              disabled={!touched}
            >
              {getMessage('device.send-status')}
            </Button>
          </CardActions>
        </Card>
      }
    </React.Fragment>
  )
}

export default Device

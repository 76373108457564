import { useEffect, useState } from 'react'


function getPersistedValue(key, initialValue) {
  const item = localStorage.getItem(key)
  const persistedValue = item ? JSON.parse(item) : null;
  if (persistedValue !== null) return persistedValue;
  if (initialValue instanceof Function) return initialValue()
  return initialValue;
}

export default function useLocalState(key, initialValue) {
  const [value, setValue] = useState(() => getPersistedValue(key, initialValue));

  const updateFromStorage = event => {
    if (event.key === key) setValue(JSON.parse(event.newValue));
  }

  useEffect(() => {
    if (key) {
      setValue(getPersistedValue(key, initialValue))
      window.addEventListener('storage', updateFromStorage)
    }
    return () => document.removeEventListener('storage', updateFromStorage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key])

  const setPersistedValue = newValue => {
    const _newValue = newValue instanceof Function ? newValue(value) : newValue
    if (_newValue !== undefined) localStorage.setItem(key, JSON.stringify(_newValue));
    setValue(_newValue);
  }

  return [value, setPersistedValue];
}
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SaveIcon from '@mui/icons-material/Save';
import OpenIcon from '@mui/icons-material/OpenInBrowser';
import { MessageContext } from '../../Providers/MessageProvider';
import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button } from '@mui/material';
import { DeviceContext } from '../../Providers/DeviceProvider';
import getConfigFileName from '../../Helpers/getConfigFileName';
import { saveDeviceConfig } from '../../Helpers/saveDeviceConfig';

const DeviceConfigManager = ({ value, onChange }) => {
  const { formatMessage } = useContext(MessageContext)
  const { deviceName } = useContext(DeviceContext)
  const [description, setDescription] = useState("")
  const [fileName, setFilename] = useState("")

  const [dialogOpen, setDialogOpen] = useState(false)
  const inputFile = useRef(null)

  const getConfigMessage = useCallback((messageId, data) => {
    return formatMessage(`device.config.${messageId}`, data)
  }, [formatMessage])

  const actions = [
    { icon: <OpenIcon />, name: 'open' },
    { icon: <SaveIcon />, name: 'save' },
  ];

  useEffect(() => {
    setFilename(getConfigFileName(deviceName, description))
  }, [description, deviceName])

  const handleDialogClose = useCallback((isValid) => {
    if (isValid) {
      if (dialogOpen === "save") {
        saveDeviceConfig(deviceName, description, value)
      }
      else if (dialogOpen === 'open') {
        inputFile.current.click()
      }
    }
    setDialogOpen(false)
  }, [description, deviceName, dialogOpen, value])

  const handleFileChange = useCallback((event) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event?.target?.files?.[0]
    const reader = new FileReader()

    reader.onload = function (e) {
      try {
        const config = JSON.parse(e.target.result)
        if (!!config?.state) {
          onChange(config.state)
        }
      }
      catch (error) {

      }
    }
    reader.readAsText(file)
  }, [onChange])

  return (
    <>
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{ position: 'absolute', top: 90, right: 16 }}
        direction="down"
        icon={<SpeedDialIcon />}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={getConfigMessage(`${action.name}.title`)}
            onClick={() => setDialogOpen(action.name)}
          />
        ))}
      </SpeedDial>
      <Dialog open={!!dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>{!!dialogOpen && getConfigMessage(`${dialogOpen}.title`)}</DialogTitle>
        <DialogContent>
          <DialogContentText>{!!dialogOpen && getConfigMessage(`${dialogOpen}.message`)}</DialogContentText>
          {dialogOpen === "save" && (
            <>
              <TextField
                autoFocus
                margin="dense"
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                label={getConfigMessage(`${dialogOpen}.description-label`)}
                type="text"
                fullWidth
                variant="standard"
                helperText={getConfigMessage(`${dialogOpen}.filename`, { fileName })}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)}>{getConfigMessage(`${dialogOpen}.cancel`)}</Button>
          <Button onClick={() => handleDialogClose(true)}>{getConfigMessage(`${dialogOpen}.valid`)}</Button>
        </DialogActions>
      </Dialog>
      <input type='file' id='file' ref={inputFile} onChange={handleFileChange} accept=".ibap" style={{ display: 'none' }} />
    </>
  )
}

export default DeviceConfigManager